<template>
	<main>
		<h1 v-if="affiliate.id">
			Edit affiliate
		</h1>
		<h1 v-else>
			Create affiliate
		</h1>

		<div v-if="!affiliate.id" class="columns">
			<div>
				<label for="domain">Domain *</label>
				<input id="domain" v-model="affiliate.domain" type="text" autocomplete="off" />
			</div>
		</div>
		<div v-else class="columns">
			<div>
				<label for="name">Name</label>
				<input id="name" :value="affiliate.name" type="text" readonly />
			</div>
			<div>
				<label for="domain">Domain</label>
				<input id="domain" :value="affiliate.domain" type="text" readonly />
			</div>
		</div>

		<div>
			<table>
				<thead>
					<tr>
						<th scope="col">
							Courses
						</th>
						<th scope="col">
							&nbsp;
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="course in courses" :key="course.id">
						<td>
							{{ course.name }}
						</td>
						<td scope="col">
							<BoolInput :name="course.id" :checked="hasCourse(course.id)" @input="checked => toggleCourse(checked, course.id)" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div>
			<BaseButton @click="saveChanges">
				Save changes
			</BaseButton>
			<BaseButton v-if="affiliate.id" type="delete" @click="remove">
				Remove
			</BaseButton>
		</div>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton.vue';
	import Bus from '../../inc/bus';
	import {roleGuard} from '../../inc/auth';
	import BoolInput from '../../components/BoolInput';
	import Router from '../../inc/router';

	export default {
		...roleGuard('admin'),
		components: {
			BaseButton,
			BoolInput
		},
		data() {
			return {
				affiliate: {
					name: '',
					domain: '',
					courses: []
				},
				courses: []
			};
		},
		created() {
			if(this.$route.params.id) {
				Backend.get('affiliates/' + this.$route.params.id).then(res => {
					this.affiliate = res.data;
				});
			}

			Backend.get('courses').then(res => {
				this.courses = res.data;
			});
		},
		methods: {
			saveChanges() {
				if(this.$route.params.id) {
					Backend.put('affiliates/' + this.affiliate.id, this.affiliate)
						.then(() => Bus.emit('success', 'Changes saved!'));
				}
				else {
					if(!this.affiliate.domain) {
						Bus.emit('error', 'You must enter a valid domain.');

						return;
					}

					if(!this.affiliate.courses.length) {
						Bus.emit('error', 'You must check at least one course.');

						return;
					}

					Backend.post('affiliates', this.affiliate)
						.then(res => {
							this.affiliate = res.data;

							return Router.push({name: 'AdminAffiliateEdit', params: {id: res.data.id}});
						})
						.then(() => Bus.emit('success', 'Changes saved!'));
				}
			},
			remove() {
				Backend.delete('affiliates/' + this.affiliate.id)
					.then(() => Router.push({name: 'AdminAffiliates'}))
					.then(() => Bus.emit('success', 'Affiliate removed'));
			},
			toggleCourse(checked, courseId) {
				if(checked) {
					this.affiliate.courses.push(courseId);
				}
				else {
					this.affiliate.courses = this.affiliate.courses.filter(c => c !== courseId);
				}
			},
			hasCourse(courseId) {
				return this.affiliate.courses.includes(courseId);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.columns {
		display: flex;
		gap: $default_padding;

		> * {
			flex: 1;
		}
	}

	table {
		max-width: none;
		table-layout: fixed;

		th + th,
		td + td {
			width: 72px;
			padding: 0;
			text-align: center;
		}

		label {
			margin: 0;
		}

		.global td {
			border-bottom: 2px solid rgba($color__dark, 0.2);
		}
	}
</style>